import React from "react"
import { Link } from "gatsby"

/**
 * CtaAccent component.
 *
 * @param {object} props - The component props.
 * @param {string} props.headline - The headline.
 * @param {string} props.description - The description.
 * @param {string} props.path - The path for the internal link.
 * @param {string} props.url - The URL for the external link.
 * @param {string} props.linkText - The text for the link.
 * @returns {JSX.Element} The rendered CtaAccent component.
 */
const CtaAccent = ({ headline, description, path, url, linkText }) => {
  return (
    <section className="cta-accent cta-accent__rightrail">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="ghost-header">
              <h2 className="ghost-header-title">{headline}</h2>
            </div>

            <div className="cta-accent-description">
              <p>{description}</p>
            </div>

            {url && (
              <a href={url} className="uta-btn uta-btn-inverse">
                <span>{linkText}</span>
              </a>
            )}
            {path && (
              <Link to={path} className="uta-btn uta-btn-inverse">
                <span>{linkText}</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CtaAccent
